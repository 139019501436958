@import "../../global.scss";

.navbar {
    background-color: map-get($theme-colors, "accent5");
    border-color: map-get($theme-colors, "accent5");
    padding-left: 2rem;
}

.title {
    color: #fff;
    float: left;
    font-weight: bold;
    font-size: 1.1em;
}

.link {
    background-color: map-get($theme-colors, "primary");

    &:hover {
        background-color: map-get($theme-colors, "accent5");
        text-decoration: none;
    }
}
