/* Some of the styles from devint.hcch.com:9000 -- revisit required if this needs to be completed */

.leftNav {
    padding: 0 5px 0 0;
    margin-top: 1px;
}

.verticalNavigation {
    border-radius: 0;
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%);

    a {
        color: #555;
        padding: 0.75rem 1.5rem;
        font-size: 13px;
        margin: 0;
        border: none;
        border-bottom: 1px solid #dcdcdc;
        background: #fff;

        &:hover {
            color: #555;
            text-decoration: none;
            background-color: #d9d2cc;
        }

        .glyphicon.leftglyph {
            padding: 0 5px 0 0
        }
    }

}