﻿/* Customize theme based on http://devint.hcch.com:9000/ */
$theme-colors: ( "primary": #d6822a, "secondary": #999999, "success": #5cb85c, "info": #5bc0de, "warning": #f0ad4e, "danger": #d9534f, "light": #fcb426, "dark": #4097a1, "accent1": #483728, "accent2": #a22a2a, "accent3": #466947, "accent4": #eff1ed, "accent5": #004c23, "accent6": #372d2c, "accent7": #cdd4cb, "accent8": #007681, "accent9": #e3e8ed, "accent10": #4d758c, "accent11": #b3bdae, "accent12": #93d50a, "accent13": #63c7c7);
$link-color: #0563c1;
$link-decoration: none;
$link-hover-decoration: underline;
$min-contrast-ratio: 2.0;
$btn-font-weight: 600;

/* import bootstrap */
@import "~bootstrap/scss/bootstrap";

html, body {
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 1.42857143em;
    color: #222;
    background-color: #e1e1e1;
}

h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 15px;
    margin-bottom: 20px
}

h1 {
    font-size: 2.5em;
    color: #275431;
    font-family: "ashburylight",serif;
    font-weight: normal
}

#mainContent {
    background: #fff;
}

.card-header {
    background: transparent;
}

.btn{
    text-transform: uppercase;
}



footer {
    padding: 20px 0;
    text-align: center
}

footer h6 {
    color: #414141;
    display: inline;
    font-size: .846em;
    font-style: italic
}

footer h5 {
    color: #ccc;
    font-size: .923em;
    margin-top: 2px
}

footer a {
    color: #414141;
    text-decoration: none
}
